import { ProfileService } from "src/app/modules/supplier/services/profile.service";
import { CommonService } from "./../../../../../services/common.service";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { cloneDeep, dropDownSortingByText } from "src/app/utils";
import {
  AP_ReviewStatus,
  StatusType,
  SupplierStatus,
  LoadingUrlMapping,
} from "src/app/interfaces/mapping";
import {
  ButtonCommand,
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { SupplierFinancial } from "src/app/interfaces/supplierProfile";
import VMController from "src/app/interfaces/vm";
import { environment } from "src/environments/environment";
import { LoadingService } from "src/app/services/loading.service";
import { supplierGeographical } from "src/app/shared/shared";
import {
  SetupProcess,
  ProcessNames,
  SupplierChangeRequestProcess,
  AllTaskKey,
} from "src/app/interfaces/workflow";
import { AuthService } from "src/app/services/auth/auth.service";
import { ComponentConfig } from "src/app/dynamic-components/ComponentConfig";
import { DynamicContext } from "src/app/dynamic-components/interfaces/DynamicContext";
import { OnBoardingService } from "src/app/modules/buyer/services/onboarding.service";
import { MetadataService } from "src/app/services/metadata.service";
import { DynamicFormComponent } from "src/app/dynamic-components/components/dynamic-form/dynamic-form.component";
import { Validators } from "@angular/forms";
import { trim } from "src/app/utils";
import { SupplierCommonService } from "../../../services/supplierCommon.service";
import { BuyerService } from "src/app/services/buyer.service";
import { LanguageService } from "../../../../../dynamic-components/utils/language.service";
import { showGlobalPrepopulateData } from "../../../../../../config/config";
import { SupplierService } from "../../../../../services/supplier.service";
import { MatDialog } from '@angular/material/dialog';
import { GlobalPopupComponent } from "src/app/global-popup/global-popup.component";

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.sass"],
})
export class FinancialComponent implements OnInit {
  isTsmId = false;
  getConfigsInfo: ComponentConfig[] = [];
  originalConfig: ComponentConfig[] = [];
  context: DynamicContext;
  @ViewChild("dynamicForm") dynamicForm: DynamicFormComponent;

  // AP_ReviewStatus = AP_ReviewStatus
  // apReviewStatus: string
  isValidated: boolean;
  isDisplayRequierdVerify = false;
  isNew = true;
  globalAnwsers = [];
  globalNameElement:any;
  dialogRef: any;
  getglobalVal: any;
  get financialInfo(): SupplierFinancial {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial.RequestorEmailAddress =
      this.profileService.supplierModel.Mapping.requestor.createdBy;
    return this.profileService.supplierModel.SupplierProfile.SupplierFinancial;
  }

  set financialInfo(val) {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial = val
  }

  get isView() {
    if (!this.profileService.isView) {
      this.getConfigsInfo = this.originalConfig;
    }
    return this.profileService.isView;
  }

  vm: VMController<SupplierFinancial> = new VMController();
  isResubmitDisabled = true;

  constructor(
    private profileService: ProfileService,
    private router: Router,
    private commonService: CommonService,
    private dialogService: DialogService,
    private supplierService: SupplierService,
    private loadingService: LoadingService,
    private metadataService: MetadataService,
    private onBoardingService: OnBoardingService,
    private auth: AuthService,
    private supplierCommonService: SupplierCommonService,
    private buyerService: BuyerService,
    private languageService: LanguageService,
    private cd: ChangeDetectorRef,
   private dialog: MatDialog
  ) {
    this.profileService.stepChangeEvent.emit(3);
    this.isTsmId = !!this.profileService.supplierModel.Mapping.TsmId;
  }

  async ngOnInit() {
    this.loadingService.showLoading();
    this.profileService.current_step = 3;
    this.context = new DynamicContext();
    // load current login user's task
    if (this.isView) {
      this.context.mode = "formView";
    }

    await this.profileService.loadUserTasks();
    await this.supplierCommonService.SetContextValue(this.context);
    const paymentTermListparams = {
      companyCode:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .CompanyCode,
      geographicalUnit:
        this.profileService.supplierModel.Mapping.geographicalUnit,
      category:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCategoryGroup,
      spendCommodityGroup:
        this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
          .SpendCommodityGroup,
      enterpriseType:
        this.profileService.supplierModel.SupplierProfile.SupplierOrganization
          .EnterpriseType,
    };
    const result: any = await this.buyerService.getPaymentTermList(
      paymentTermListparams
    );
    if (result && result.isSuccess) {
      this.context.set("standardPaymentTerms", result.data);
    }

    this.getConfigsInfo = await this.metadataService.getPageConfiguration(
      "supplier-profile-step3"
    );
    this.originalConfig = cloneDeep(this.getConfigsInfo);
    this.context.set(
      "parentModel",
      cloneDeep(this.profileService.supplierModel)
    );
    this.context.set("originalFinancialInfo", cloneDeep(this.financialInfo));
    //  this.dynamicForm.subscribeToModelChange('BankNm', () => {
    //    this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()

    //  })
    // this.dynamicForm.subscribeToModelChange('AccountHolderNm', () => {
    //   this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()

    // })
    // this.dynamicForm.subscribeToModelChange('Account', () => {
    //   this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()
    // })
    // this.dynamicForm.subscribeToModelChange('BankKey', () => {
    //   this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()

    // })
    // this.dynamicForm.subscribeToModelChange('BIC_SwiftCd', () => {
    //   this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()
    // })

    if (
      this.profileService.supplierModel.RequestForm.RequestorDetailsInfo
        .SupplierGeographical === supplierGeographical.PH
    ) {
      this.financialInfo.PreferredOrderingMethod = "Print";
    }
    // this.context.on('AddFinancialAttachments', (fileList: any) => {
    //   if (fileList !== '') {
    //     this.dynamicForm.formGroup.get('AddFinancialAttachments').patchValue(fileList)
    //     this.dynamicForm.formGroup.get('AddFinancialAttachments').clearValidators()
    //     this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()
    //   }
    //   else {
    //     this.dynamicForm.formGroup.get('AddFinancialAttachments').setValidators(Validators.required)
    //     this.dynamicForm.formGroup.get('AddFinancialAttachments').updateValueAndValidity()
    //   }
    // })

    // if (!this.financialInfo.AddFinancialAttachments) {
    //   this.financialInfo.AddFinancialAttachments = []
    // }

    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });

    // this.apReviewStatus = this.profileService.supplierModel.Mapping.AP_reviewStatus
    // }
    // async ngAfterViewChecked() {
    //   this.cd.detectChanges()
    if (!this.isView) {
      this.dynamicForm.subscribeToFormChange(() => {
        setTimeout(() => {
          this.isResubmitDisabled =
            JSON.stringify(this.dynamicForm.originalValues()) ===
            JSON.stringify(this.dynamicForm.values());
        });
      });
    }
    this.loadingService.hideLoading();
    // console.log('MODE', this.onBoardingService.mode)
    if ((this.onBoardingService.mode == 'edit' || this.onBoardingService.mode == 'create') && showGlobalPrepopulateData ){
      this.formAutofill()
    }
  }

  async formAutofill() {
    let payload = {
      supplierCode: this.profileService.supplierCode, 
      SupplierCompanyName: this.profileService.supplierModel.SupplierProfile.SupplierGeneral.CompanyName,
      supplierTsmId: this.profileService.supplierModel.Mapping.TsmId,
      countryServed: this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry,
      RoleCode: this.auth.passport.supplierMember.RoleCode
    }
    // console.log('formAutofill Global payload', payload);
    if(payload.supplierCode && payload.supplierTsmId){
      let getGlobalQAs;
      if (localStorage.getItem("globalqa") == null) {
        const getGlobalQAs = await this.supplierService.getGlobalFieldData(payload);
        localStorage.setItem("globalqa", JSON.stringify(getGlobalQAs))
      }
      else {
        getGlobalQAs = JSON.parse(localStorage.getItem("globalqa"));
      }
    // console.log('formAutofill getAllAnswers', getGlobalQAs);
      if (this.getConfigsInfo && this.getConfigsInfo.length && getGlobalQAs) {
      this.getConfigsInfo.forEach(item => {
        item['fieldset'].forEach(field => {
          // console.log('field', field)
          // console.log('Global', field['global'])
          if (field['global']) {
            const filterAnwsers = getGlobalQAs.filter((question) => question['QuestionName'] == field['name'])
            this.globalAnwsers.push(...filterAnwsers)
          }
        })
      })
    }
    // console.log('formAutofill globalAnwsers', this.globalAnwsers)
    let financialInfoData = Object.assign({}, this.financialInfo)
    // console.log('formAutofill financialInfoData', financialInfoData);  
    this.globalAnwsers.forEach((anwserItem) => {
      if (anwserItem != null && anwserItem != undefined) {
        // console.log('formAutofill this.financialInfo', this.financialInfo);  
        if((this.financialInfo[anwserItem['QuestionName']] == "") || (this.financialInfo[anwserItem['QuestionName']] == null) || (this.financialInfo[anwserItem['QuestionName']] == undefined)){
          // console.log('formAutofill Prepopulate Values', anwserItem['Answer']);    
          financialInfoData[anwserItem['QuestionName']] = anwserItem['Answer']
        }
      }
    })
    this.financialInfo = financialInfoData
    // console.log('formAutofill this.financialInfo', this.financialInfo);  
    setTimeout(() => {
      console.log('formAutofill Calling updateFormValues');  
      this.dynamicForm.updateFormValues()
    });
  }
  setTimeout(() => {     
    if(!localStorage.getItem('financialsaved') && localStorage.getItem('financialsaved') !== 'yes') {
      this.SaveFirsttime();
    }            
    console.log("settimeout");
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }

    for (const element of elements) {
      element.addEventListener("change", (e) => {
        // console.log("Field change??? ===>", e);
        const targetElement = e.currentTarget as HTMLElement;
        if (targetElement) {
          targetElement.style.border = 'solid 1px #414187';
        }
      });
    }
  });
}

  highlightInput() {
    const elements: HTMLInputElement[] = [];

    for (let gobalVal of this.globalAnwsers) {
      let formControlType: string;

      if (gobalVal['AnswerInputField'] === 'dropdown') {
        formControlType = 'select';
      } else if (gobalVal['AnswerInputField'] === 'input') {
        formControlType = 'input';
      } else if (gobalVal['AnswerInputField'] === 'radio') {
        formControlType = 'radio';
      }

      const actualElement = document.querySelector<HTMLInputElement>(`[id^="${gobalVal['QuestionName']}${formControlType}"]`);

      if (actualElement) {
        elements.push(actualElement);
      }
    }''

    for (const element of elements) {
      element.style.border = '2px solid orange';
    }
  }
  async SaveFirsttime(): Promise<boolean> {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial = trim(
      this.dynamicForm.values()
    );
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraft();
    localStorage.setItem('financialsaved', 'yes');
    return true;
  }
  openGlobalPopup() {
    this.dialogRef = this.dialog.open(GlobalPopupComponent, {
      width: '450px',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      for (this.getglobalVal of this.globalAnwsers) {
        const supplierControl = this.dynamicForm.formGroup.controls[this.getglobalVal.QuestionName];
        if (supplierControl) {
          if (supplierControl.dirty) {
            if (result === 'onConfirmClick') {
              const routerPath: string = this.isTsmId
              ? `supplier/profile/${this.profileService.supplierCode}/step5`
              : `supplier/profile/${this.profileService.supplierCode}/step4`;

              this.router.navigate([routerPath]);
            } else if (result === 'onCancelClick') {
              supplierControl.setValue(this.getglobalVal.Answer);
              this.highlightInput();
            }
          }
        }
      }
    });
  }

  checkAndOpenGlobalPopup() {
    const globalDirty = this.globalAnwsers.some(globalAnswerVal => {
      const control = this.dynamicForm.formGroup.controls[globalAnswerVal.QuestionName];
      return control && control.dirty;
  });
    if (globalDirty) {
      this.openGlobalPopup();
  }
  else if(!globalDirty){
    const routerPath: string = this.isTsmId
    ? `supplier/profile/${this.profileService.supplierCode}/step5`
    : `supplier/profile/${this.profileService.supplierCode}/step4`;

    this.router.navigate([routerPath]);
  }
  }

  async canDeactivate() {
    if (this.isView) {
      return true;
    }
    this.vm.setOriginal(this.dynamicForm.originalValues());
    this.vm.setCurrent(this.dynamicForm.values());
    if (this.vm.isDirty()) {
      const result = await this.dialogService.saveConfirm();
      if (result === "save") {
        if (!(await this.saveDraft())) {
          return false;
        }
      }
      if (result === "cancel") {
        return false;
      }
    }

    return true;
  }

  async btnNext() {
    // let routerPath:string = `supplier/profile/${this.profileService.supplierCode}/step4`
    const routerPath: string = this.isTsmId
      ? `supplier/profile/${this.profileService.supplierCode}/step5`
      : `supplier/profile/${this.profileService.supplierCode}/step4`;

    this.router.navigate([routerPath]);
  }

  validate() {
    return this.dynamicForm.valide();
  }

  async resubmit() {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return;
    }
    // resubmit
    if (
      this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)
    ) {
      this.resubmitForm().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.finanical.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.finanical.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    } else {
      this.changeRequest().then((data: boolean) => {
        if (data) {
          this.vm.saved();
          this.dialogService
            .dialog(
              this.languageService.getValue(
                "static.supplier.components.finanical.change_request"
              ),
              ButtonCommands.Ok,
              DialogType.success,
              this.languageService.getValue(
                "static.supplier.components.finanical.please_note"
              )
            )
            .then((result: boolean) => {
              this.router.navigate([`supplier/landing`]);
            });
        }
      });
    }
  }

  // get isResubmitDisabled() {
  //   this.vm.setCurrent(this.financialInfo)
  //   return !this.vm.isChange()
  // }

  async saveAndNavigate() {
    this.saveDraft().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.checkAndOpenGlobalPopup();
      }
    });
  }

  // async save(): Promise<boolean> {
  //   if (!this.validate()) {
  //     return false
  //   }
  //   const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileForm}`
  //   this.loadingService.openLoading(saveUrl)
  //   if (this.profileService.profile_step < this.profileService.current_step) {
  //     this.profileService.profile_step = 3
  //   }
  //   await this.profileService.saveSupplierProfile()
  //   return true
  // }

  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    // get executable task GUID: taskInstId
    this.profileService.getResubmitTask();
    if (!this.profileService.supplierModel.Mapping.executeTask) {
      return false;
    }
    // console.log(
    //   "!this.profileService.supplierModel.Mapping.executeTask",
    //   cloneDeep(this.profileService.supplierModel)
    // );
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.resubmitSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.resubmitForm();
    // console.log(
    //   "await this.profileService.resubmitForm()",
    //   cloneDeep(this.profileService.supplierModel)
    // );
    return true;
  }

  // resubmit request form
  async changeRequest(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    await this.updateSupplierMapping();
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.requestChangeSupplierProfile}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.changeRequestForm();
    return true;
  }

  // save draft request form
  async saveDraft(): Promise<boolean> {
    this.context.changeSubmitState();
    this.dynamicForm.removeFormTouch();
    if (!this.validate()) {
      return false;
    }
    // console.log(
    //   "!this.validate()",
    //   cloneDeep(this.profileService.supplierModel)
    // );
    await this.updateSupplierMapping();
    const saveUrl = `${environment.gateway}${LoadingUrlMapping.saveProfileDraft}`;
    this.loadingService.openLoading(saveUrl);
    await this.profileService.saveDraft();
    // console.log(
    //   "this.profileService.saveDraft()",
    //   cloneDeep(this.profileService.supplierModel)
    // );
    return true;
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.profileService.supplierModel.SupplierProfile.SupplierFinancial = trim(
      this.dynamicForm.values()
    );       
    if (this.profileService.profile_step < this.profileService.current_step) {
      this.profileService.profile_step = 3;
    }
  }

  get showResubmitBtn() {
    if (this.dynamicForm) {
      if (
        !this.isView &&
        !this.dynamicForm.disabled &&
        this.dynamicForm.formGroup
      ) {
        this.isResubmitDisabled =
          JSON.stringify(this.dynamicForm.originalValues()) ===
          JSON.stringify(this.dynamicForm.values());
      }
    }
    return this.profileService.checkResubmit();

    // workflow comments: remove supplier change request
    // return (this.apReviewStatus === AP_ReviewStatus.SST_reject || this.profileService.supplierModel.Mapping.status === 'Onboarded')
    //   && !this.isView && !this.profileService.checkIsMigrateSubmit()
  }
}
