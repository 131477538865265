import { Task, HistoryTask, ProcessStatus } from "./workflow";
import { HoldOn } from "./HoldOn";

// Main status
export enum SupplierStatus {
  pending = "Pending",
  onboarded = "Onboarded",
  terminated = "Terminated",
}

export enum RejectComments {
  requestor = "Reject and send back to requestor",
  supplier = "Reject and send back to supplier",
}

export const privacyStatementState = {
  privacyStatement: false,
  isDisplayAccept: false,
  linkType: "",
  termOfUse: false,
  multipleSupplier: false,
};

// Supplier onboarding pending flow
export enum SupplierPendingStatus {
  inRequestor_draft = "Requestor Draft Incomplete", // request start
  requestor_draft = "Requestor Draft Complete", // request completed
  supplier_accepted = "Supplier Accepted Invitation", // profile not completed
  inProfile_draft = "Supplier Draft Incomplete", // profile start
  profile_draft = "Supplier Draft Complete", // profile completed
  SST_review = "AP SST Review Complete",
  QC_review = "AP QC Review Complete",
  terminated = "Terminated",
  onboarded = "Onboarded",
  profile_resubmit= "Supplier Form Resubmitted"
}
  

// Only start this workflow when profile_draft is completed.
export enum AP_ReviewStatus {
  SST_approve = "AP SST Review Approved",
  SST_reject = "AP SST Reject",
  form_resubmitted = "Form Resubmitted",
  SST_terminate = "AP SST Terminated",
  QC_approve = "AP QC Review Approved",
  QC_reject = "AP QC Reject",
  QC_terminate = "AP QC Terminated",
  Supplier_request_change = "Supplier Change Request",
  Buyer_request_change = "Requestor Change Request",
}

// AP flow status key
export enum AP_ReviewMapping {
  SST_approve = "SST_review",
  SST_reject = "SST_review",
  SST_terminate = "terminated",
  QC_approve = "QC_review",
  QC_reject = "QC_review",
  QC_terminate = "terminated",
}

export enum RiskAssessmentStatus {
  IS = "Information Security Risk Assessment Completed", // Only start when requestor_draft is completed.
  GDPR = "GDPR Risk Assessment Completed", // Only start when requestor_draft is completed.
  BIBDA = "BI/BDA Risk Assessment Completed", // Only start when requestor_draft is completed.
  SAP = "Integrate with SAP", // Only start when AP_ReviewStatus workflow is completed.
}

// All status key
export enum StatusType {
  pending = "pending",
  risk = "risk",
  AP_review = "AP_review",
  other = "other",
  onboarded = "onboarded",
  terminated = "terminated",
  Supplier_request_change = "Supplier_request_change",
  Buyer_request_change = "Buyer_request_change",
}

export enum StatusHistoriesTitle {
  requestor_draft = "The Requestor has completed Requestor Draft",
  supplier_accepted = "The supplier has accepted invitation",
  profile_draft = "The supplier has completed SupplierDraft",
  SST_review = "AP SST Review Approved",
  QC_review = "AP QC Review Approved",
  IS = "The IS Risk Assessment has been completed",
  GDPR = "The GDPR Risk Assessment has been completed",
  BIBDA = "The BI/BDA Risk Assessment has been completed",
  SAP = "The AP QC has approved",
  SST_approve = "The AP SST has approved",
  SST_reject = "The AP SST has rejected",
  SST_terminate = "The AP SST has terminated",
  QC_approve = "The AP QC has approved",
  QC_reject = "The AP QC has rejected",
  QC_terminate = "The AP QC has terminated",
  onboarded = "The onboarding process has been completed",
  terminated = "The supplier has been terminated",
  Supplier_request_change = "Change Supplier - Supplier Form",
  Buyer_request_change = "Change Request - Requestor Form",
}

export interface PocEmailEvidence {
  name?: string;
  key?: string;
  imgUrl?: string;
}

export interface StatusModel {
  [key: string]: {
    status?: string;
    createdOn?: string;
    createdBy?: string;
    isStarted?: boolean;
    isDisplay?: boolean;
    isAPReview?: boolean;
    role?: string;
    historiesTitle?: string;
    rejectTo?: string;
    processName?: string;
    comments?: string;
    pocEmailEvidence?: Array<PocEmailEvidence> | undefined;
  };
}

export interface SapResultModel {
  actionTaken?: string;
  sapResultList?: Array<SapResultItem>;
  thirdPartySAPResult?: Array<ThirdPartySapResultItem>;
  alterNativePayeeResult?: Array<AlternativePayInformation>;
  gstSAPList?: Array<GstRegistrationSapItem>;
  gstPartnerResult?: Array<GSPartnerInformation>;
  value?: string;
  comments?: string;
  migrateCCEmail?: string;
  type?: string;
  createdOn?: string;
  createdBy?: string;
}

export interface SapResultItem {
  WTaxUUID?: string;
  countryCode?: string;
  companyCode?: string;
  WTaxType?: Array<string>;
  WTaxCode?: Array<string>;
  WTaxID?: string;
  sapId?: string;
  WTaxTypeList?: Array<any>;
  WTaxCodeList?: Array<any>;
  twoSeries?: boolean;
  threeSeries?: boolean;
}

export interface GstRegistrationSapItem {
  gstCountryId?: string;
  gstState?: string;
  gstStateKey?: string;
  sapId?: string;
}

export interface GSPartnerInformation {
  gsPartnerName: string;
  gsPartnerList: Array<GstRegistrationSapItem>;
}

export interface ThirdPartySapResultItem {
  thirdPartyId?: number;
  thirdPartyName?: string;
  sapId?: string;
}

export interface AlternativePayInformation {
  alternativePayName: string;
  thirdPartyList: Array<ThirdPartySapResultItem>;
}
export interface SupplierMappingDTO {
  supplierCode?: string;
  supplierEmail?: string;
  buyerCode?: string;
  IsMigrated?: boolean;
  IsInvited?: boolean;
  IsChangeRequest?: string;
  InvoiceReference?: string;
  Difference?: Array<string>;
  InvitedDate?: string;
  geographicalUnit?: string;
  setUpCountry?: Array<string>;
  InvitedStatus?: string;
  onHold?: Array<HoldOn>;
  paymentTermsIsChange?: boolean;
  // workflow start

  status?: SupplierStatus;
  historyTasks?: Array<HistoryTask>;
  buyerChangeRequestStatus?: ProcessStatus;
  supplierChangeRequestStatus?: ProcessStatus;
  supplierMigrateSetupStatus?: ProcessStatus;
  // execute task
  executeTask?: Task;

  // workflow end
  PO?: string;

  TsmId?: string;
  searchTerm?: {
    supplierName?: string;
    companyCode?: string[];
    SAP?: string[];
    thirdPartySAP?: string[];
    category?: string;
    BRN?: string;
    createBuyer?: string;
    InvitedStatus?: string;
  };
  requestor?: {
    step?: number;
    createdOn?: string;
    createdBy?: string;
    hash?: string;
    completedTime?: string;
    updatedBy?: string;
    tsmTaxId? : string;
    tsmTaxType?: string;
    tsmTaxCategoryFieldName? : string;
    businessregistrationtype?: string;
  };
  profile?: {
    supplierAccepted: string;
    step?: number;
    createdOn?: string;
    createdBy?: string;
    hash?: string;
    BRN?: string;
    completedTime?: string;
    isUpdated?: boolean;
  };
  assessmentRate?: {
    bibdaResult?: {
      score?: string;
      bibdaCaseId?: string;
      bibdaComments?: string;
      isCommited?: boolean;
    };
    sapResult: Array<SapResultModel>;
  };
  gstSapItem?: Array<GstRegistrationSapItem>;
  wTaxID?: Array<WTaxIDModel>;
  activeOnhold?: boolean;
  taxIdsFromTsm?: Array<TsmTaxMappingDTO>
}

export interface WTaxIDModel {
  WTaxUUID?: string;
  WTaxID?: string;
}

export function createSupplierMapping(supplierCode) {
  const mapping: SupplierMappingDTO = {
    supplierCode,
    buyerCode: "accenture",
    supplierEmail: "",
    status: SupplierStatus.pending,
    // detailStatus: SupplierPendingStatus.inRequestor_draft,
    IsMigrated: false,
    IsInvited: false,
    geographicalUnit: "",
    setUpCountry: [],
    InvitedStatus: InvitedStatusMapping.new,
    // statusHistories: [],
    // AP_statusHistories: [],
    searchTerm: {},
    PO: "",

    // work flow
    historyTasks: [],
    // workflow
    requestor: {
      step: 1,
      createdOn: "",
      createdBy: "",
      hash: "",
      completedTime: "",
      updatedBy: "",
    },
    profile: {
      supplierAccepted: "new",
      step: 1,
      createdOn: "",
      createdBy: "",
      hash: "",
      BRN: "",
      completedTime: "",
      isUpdated: false,
    },
    assessmentRate: {
      bibdaResult: {
        score: "",
        bibdaCaseId: "",
        bibdaComments: "",
        isCommited: false,
      },
      sapResult: [],
    },
    wTaxID: [],
  };
  return mapping;
}

export function createAPReviewModel() {
  const apValue: SapResultModel = {
    actionTaken: "",
    sapResultList: [],
    value: "",
    comments: "",
    type: "",
    createdOn: "",
    createdBy: "",
  };
  return apValue;
}

export function createAPApproveItem() {
  const apResultItem: SapResultItem = {
    WTaxUUID: "",
    countryCode: "",
    companyCode: "",
    WTaxType: [],
    WTaxCode: [],
    WTaxID: "",
    sapId: "",
    WTaxTypeList: [],
    WTaxCodeList: [],
    twoSeries: false,
    threeSeries: false,
  };
  return apResultItem;
}

export function createSSTApproveThirdPartyItem() {
  const thirdPartyItem: ThirdPartySapResultItem = {
    thirdPartyId: 0,
    thirdPartyName: "",
    sapId: "",
  };
  return thirdPartyItem;
}

// Category group mapping with other form
export const SpendCategoryGroup = {
  "1": true,
  "2": false,
  "3": true,
  "4": true,
  "5": true,
  "6": false,
  "7": true,
  "8": true,
  "9": false,
  "10": false,
  "11": false,
  "12": false,
  "13": true,
  "14": true,
  "15": false,
  "16": true,
  "17": true,
  "18": true,
  "19": true,
  "20": true,
  "21": true,
  "23": true,
  "24": true,
  "25": true,
  "26": false,
  "27": true,
  "28": false,
  "29": false,
  "30": false,
  "31": false,
  "32": true,
  "33": true,
  "34": false,
  "35": true,
  "36": false,
  "37": true,
  "38": true,
  "39": true,
  "40": true,
  "41": true,
  "42": true,
  "43": true,
  "44": true,
  "46": false,
  "47": true,
  "48": false,
  "49": true,
  "50": true,
  "51": false,
  "52": false,
  "53": false,
  "54": false,
  "55": true,
  "1000": true,
  "1001": true,
  "1002": true,
  "1003": true,
  "1004": true,
  "1005": true,
  "1006": true,
  "1007": true,
  "1008": true,
  "1009": true,
  "1010": true,
  "1011": true,
  "1012": true,
  "1013": true,
  "1014": true,
  "1015": true,
  "1016": true,
  "1017": true,
  "1018": true,
  "1019": true,
  "1020": true,
  "1022": true,
  "1023": true,
  "2000": true,
  "2002": true,
  "2003": true,
  "2004": true,
  "2001": true,
  "2005": true,
  "2006": true,
  "2007": true,
  "2401": true,
  "2402": false,
  "2403": false,
  "3000": true,
  "3002": true,
  "3003": true,
  "3004": true,
  "3005": true,
  "3007": true,
  "3008": true,
  "3010": true,
  "3011": true,
  "3012": true,
  "3013": true,
  "3014": true,
  "3901": true,
  "4000": false,
  "4001": false,
  "4002": false,
  "4003": true,
  "4004": true,
  "4005": false,
  "4006": false,
  "4007": true,
  "4008": true,
  "4009": false,
  "4010": false,
  "4011": false,
  "4012": false,
  "4013": false,
  "4014": true,
  "4901": true,
  "5001": true,
  "5002": true,
  "5003": true,
  "5004": true,
  "5005": true,
  "5006": true,
  "5007": true,
  "5008": true,
  "5009": true,
  "5010": true,
  "5011": false,
  "5012": false,
  "5013": false,
  "5014": false,
  "5015": false,
  "5016": false,
  "5017": true,
  "5018": true,
  "5019": true,
  "5020": true,
  "5021": true,
  "6001": true,
  "6002": false,
  "6003": false,
  "6004": false,
  "6005": false,
  "6006": false,
  "6007": false,
  "6008": true,
  "6009": false,
  "6010": false,
  "6011": false,
  "6012": false,
  "6013": false,
  "7005": true,
  "7006": true,
};

export enum LoadingUrlMapping {
  submitRequestForm = "/supplier/management/user/create/false",
  saveProfileForm = "/supplier/profile",
  inviteUser = "/buyer/send/invitation/invitation",
  replaceInviteUser = "/buyer/send/invitation/replace",
  buyerAddUser = "/buyer/management/user/create",
  supplierAddUser = "/supplier/management/user/create/true",
  addChangeRequest = "/buyer/cr/save",
  applyHoldOn = "/holdOn/saveHoldOn",
  // sstUpdateCR = '/buyer/cr/sstUpdate',
  // qcUpdateCR = '/buyer/cr/qcUpdate',
  // workflow
  saveRequestDraft = "/buyer/requestorForm/saveDraft",
  submitRequestDraft = "/buyer/requestorForm/submitDraft",
  resubmitRequestForm = "/buyer/requestorForm/resubmit",
  buyerCR_SSTApprove = "/buyer_CR/apReview/sstApprove",
  buyerCR_SSTClose = "/buyer_CR/apReview/sstClose",
  buyerCR_QCApprove = "/buyer_CR/apReview/qcApprove",
  buyerCR_QCclose = "/buyer_CR/apReview/qcClose",
  buyerCR_QCReject = "/buyer_CR/apReview/qcReject",
  AP_SST_Approve = "/buyer/apReview/sstApprove",
  AP_SST_RejectToBuyer = "/buyer/apReview/sstReject/toBuyer",
  AP_SST_RejectToSupplier = "/buyer/apReview/sstReject/toSupplier",
  AP_SST_Terminate = "/buyer/apReview/sstTerminate",
  AP_QC_Approve = "/buyer/apReview/qcApprove",
  AP_QC_Reject = "/buyer/apReview/qcReject",
  AP_QC_Terminate = "/buyer/apReview/qcTerminate",
  // supplier
  saveProfileDraft = "/supplier/profileForm/saveDraft",
  completeProfileDraft = "/supplier/profileForm/submitDraft",
  resubmitSupplierProfile = "/supplier/profileForm/resubmit",
  requestChangeSupplierProfile = "/supplier/profileForm/changeRequest",
  completeMigrateProfileDraft = "/supplier/profileForm/migrate/submitDraft",

  metricsReport = "buyer/metricsReportDownload",
  rawDataReport = "buyer/rawDataReportDownload",
  dsReport = "buyer/dsReportDownload",
  changeAvanadeCodeReport = "/buyer/changeAvanadeCodeReportDownload",
  saveEditHold = "/holdOn/saveHoldOn",

  terminateSupplier = "/buyer/terminateSupplier",
  terminateValueList = "/buyer/management/terminateValueList",
}

export enum InvitedStatusMapping {
  new = "New",
  invited = "Invited & Sent",
  accepted = "Invited & accepted",
}

export enum CR_AP_ReviewStatus {
  SSTApproveChangeRequest = "SST Approve Change Request",
  SSTCloseChangeRequest = "SST Close Change Request",
  QCApproveChangeRequest = "QC Approve Change Request",
  QCCloseChangeRequest = "QC Close Change Request",
  QCRejectChangeRequest = "QCT Reject Change Request",
}

export interface TsmTaxMappingDTO {
  taxcategory?: string,
  businessregistrationtype?: string,
  tax_registration_number?: string
}
